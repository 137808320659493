'use strict';
export const LoadMap = (params) => {
  if (!params || params.length === 0) {
    return;
  }
  const { latitude, longitude, max_drive_distance_radius} = params;
  var urlBase = 'https://api.mapbox.com/isochrone/v1/mapbox/';
  var profile = 'driving';
  var minutes = max_drive_distance_radius;


  if (!window.mapboxgl){
    return;
  }

  mapboxgl.accessToken = 'pk.eyJ1IjoidmljdG9yZmVycmF6IiwiYSI6ImNrN2djZWdybTAwNXkzZW9jZGFsa2s5bTQifQ.Wn8hBE9odu2EufmFrd915g  ';

  var map = new mapboxgl.Map({
    container: 'map', // Specify the container ID
    style: 'mapbox://styles/mapbox/streets-v11', // Specify which map style to use
    center: [longitude,latitude], // Specify the starting position
    zoom: 8, // Specify the starting zoom
  });


  function getIso() {
    var query = urlBase + profile + '/' + longitude + ',' + latitude + '?contours_minutes=' + minutes + '&polygons=true&access_token=' + mapboxgl.accessToken;
    $.ajax({
      method: 'GET',
      url: query
    }).done(function(data) {
      map.getSource('iso').setData(data);
    })
  };


  map.on('load', function() {
    map.addSource('iso', {
      type: 'geojson',
      data: {
        "type": 'FeatureCollection',
        "features": []
      }
    });
    map.addLayer({
      'id': 'isoLayer',
      'type': 'fill',
      'source': 'iso',
      'layout': {},
      'paint': {
        'fill-color': '#5a3fc0',
        'fill-opacity': 0.3
      }
    }, "poi-label");
    getIso();
  });
}