'use strict';
import moment from 'moment';
import $ from 'jquery';
import { ENV_TIDY } from './env/env';
export function Gift() {
  this.card = {};
  this.stripeErrorMessage = true;
  this.dataGift = {};
  this.sending = false;
  this.isValidDate = false;
  this.isAmount = false;
  this.env = ENV_TIDY;
  this.setStripe();
  this.eventListeners();
}

Gift.prototype.eventListeners = function () {
  document.getElementById('form-gifts').addEventListener('submit', this.sendData.bind(this));
  var self = this;
  this.card.addEventListener('change', (response) => {
    this.onChangeCreditCard.call(self, response);
  });
  $("input[name='sendToday']").change(this.changeData.bind(this));
  $('#sendDate').blur(this.validateData.bind(this));
  $('#giftAmount').blur(this.validateAmount.bind(this));
};

Gift.prototype.validateAmount = function (el){
  const formatedPrice  = this.fixAmount($('#giftAmount').val());
  this.isValidAmount = (formatedPrice > 100000);
  $('#invalid-amount').hide();
  if (this.isValidAmount){
    $('#invalid-amount').show();
  }
};

Gift.prototype.validateData = function (el) {
  const date = moment($('#sendDate').val());
  const today = moment();
  const isFuture = today.isBefore(date);
  const isNow = $("input[name='sendToday']").val() === 'true';
  this.isValidDate = date.isValid() && (isFuture || isNow) ;
  $('#invalid-date').hide();
  if (!this.isValidDate){
    $('#invalid-date').show();
  }
};

Gift.prototype.changeData = function (el) {
  if ($(el.target).val() === 'true') {
    $('#sendDate').attr('disabled', 'disabled');
    $('#sendDate').val(moment().format('YYYY-MM-DD'));
  } else {
    $('#sendDate').val(moment().add(1, 'day').format('YYYY-MM-DD'));
    $('#sendDate').attr('min', moment().add(1, 'day').format('YYYY-MM-DD'));
    $('#sendDate').removeAttr('disabled');
    $('#sendDate').attr('max', moment().add(1, 'year').format('YYYY-MM-DD'));
  }
  this.validateData();
};

Gift.prototype.onChangeCreditCard = function (response, x) {
  const displayError = document.getElementById('card-errors');
  this.stripeErrorMessage = true;
  displayError.textContent = '';
  if (response.error && response.error.message) {
    this.stripeErrorMessage = false;
    displayError.textContent = response.error.message;
  }
  displayError.style.display = 'block';
};

Gift.prototype.isValidCreditCard = function () {
  return  (this.dataGift.token !== undefined);
};

Gift.prototype.sendData = function (e) {
  e.preventDefault();
  const self = this;
  if (this.sending) {
    return;
  }
  this.validateData();
  this.validateAmount();
  if (!e.target.checkValidity() || !this.isValidDate || this.isValidAmount) {
    e.target.classList.add('was-validated');
    return;
  }
  this.stripe.createToken(this.card)
    .then(this.createToken.bind(self))
    .then(this.sendGift.bind(self))
    .catch((err) => {
      throw err;
    });
};

Gift.prototype.fixAmount = function (data) {
  const price  = data.substr(1).replace(' ', '');
  const formatedPrice = price.replace(',', '.') * 100;
  return parseInt(formatedPrice);
};

Gift.prototype.sendGift = function (e) {

  if (!this.isValidCreditCard()) {
    return;
  }
  const object = this.mountGiftObject();
  document.getElementById('sending').style.display = 'block';
  document.getElementById('error').style.display = 'none';
  this.sending = true;
  $.ajax({
    url: this.env.url + 'common/gift-purchases',
    method: 'POST',
    data: object,
    dataType: 'json'
  })
  .done(() => {
    this.sending = false;
    this.successSent();
  })
  .fail(() => {
    this.sending = false;
    document.getElementById('sending').style.display = 'none';
    document.getElementById('error').style.display = 'block';
  });
};

Gift.prototype.successSent = function () {
  const msg = {};
  const formData = this.mountGiftObject();
  msg.data = document.getElementById('sendDate').value;
  msg.yourData = formData.giver_name +  '<br>' + formData.giver_email;
  msg.recipientData = formData.recipient_name +  '<br>' + formData.recipient_email;
  msg.mount = document.getElementById('giftAmount').value;
  msg.invoiceSendData = document.getElementById('sendDate').value;
  localStorage.setItem('gift-data', JSON.stringify(msg));
  window.location.href = '/gifts-success';
};

Gift.prototype.mountGiftObject = function () {
  let amount =  document.getElementById('giftAmount').value;
  amount = this.fixAmount(amount);
  const token = this.dataGift.token;
  return {
    giver_name: document.getElementById('firstName').value,
    giver_email: document.getElementById('email').value,
    recipient_name: document.getElementById('recipentName').value,
    recipient_email: document.getElementById('recipentEmail').value,
    gift_amount: amount,
    gift_message: document.getElementById('giftMessage').value,
    send_date: document.getElementById('sendDate').value,
    currency: 'usd',
    card_token:  token
  };
};


Gift.prototype.createToken = function (result) {
  if (result.error) {
    var errorElement = document.getElementById('card-errors');
    errorElement.textContent = result.error.message;
  } else {
    this.dataGift.token = result.token.id;
  }
};


Gift.prototype.setStripe = function (){
  this.stripe = Stripe(this.env.stripe);
  const elements = this.stripe.elements();
  const style = this.getStyle();
  this.card = elements.create('card', {style: style});
  this.card.mount('#card-element');
};

Gift.prototype.getStyle = function(){
  return {
    base: {
      border: '10px solid',
      color: '#32325d',
      lineHeight: '18px',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  };
};
