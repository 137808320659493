import { ENV_TIDY } from "./env/env";
import { getParams } from "./helper/get-params";
import $ from 'jquery';

export function Unsubscribe(){
  this.getParams = getParams;
  this.env = ENV_TIDY;
  this.init();
}

Unsubscribe.prototype.init = function () {
  const email = this.getParams('email');
  const object = {
    email: email
  };
  $.ajax({
    url:  this.env.publicUrl + 'unsubscribed-emails',
    method: 'POST',
    data: object,
    dataType: 'json'
  })
  .done(function () {
    $('.success').show();
  })
  .fail(function ()  {
    $('.error').show();
  });
};
