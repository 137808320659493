'use strict';

export const generateHkPage = (params) => {
  const {
    averageReviews,
    businessName,
    certifications,
    companyDescription,
    encodedHkName,
    encodedTeamName,
    hkId,
    numberJobs,
    paymentMethods,
    phoneNumber,
    photo,
    reviews,
    services,
    slug
  } = params;

  const encodedUrl = `${hkId}/${encodedHkName}/${encodedTeamName}`;

  const data =`
    <div class="mobile-bar mobile">
      <div class="align">
        <a href="https://app.tidy.com/#/log-in/${encodedUrl}" class="regular-link">
          Log in
        </a>
        <a href="https://app.tidy.com/#/create-account/${encodedUrl}" class="green-button">
        Book
        </a>
      </div>
    </div>
    <section class="container">
        <section class="container-white-box">
            <section id="profile">
              <h1>
                ${businessName}
                <span class="powered-by">
                  <img src="https://gotidy.gumlet.io/pro-page/bow-grey.svg" /> Powered by TIDY
                </span>
              </h1>
              <div class="reviews">
                <div class="star-group">
                  ${formatStars(averageReviews)}
                </div>
                <div class="review-align">
                  <div class="reviews-number">
                    <img src="https://gotidy.gumlet.io/pro-page/chat.svg"> ${reviews.length} Reviews
                  </div>
                  <div class="phone desktop">
                    <img src="https://gotidy.gumlet.io/pro-page/phone.svg" /> ${phoneNumber}
                  </div>
                </div>
              </div>
              <div class="mobile">
                <div class="phone">
                  <img src="https://gotidy.gumlet.io/pro-page/phone.svg" /> ${phoneNumber}
                </div>
              </div>
              <div class="jobs">
                <p>
                  <img src="https://gotidy.gumlet.io/pro-page/recent-jobs.svg"> ${numberJobs} jobs in last 30 days
                </p>
              </div>
              <div class="payment-details">
                <p>
                <img src="https://gotidy.gumlet.io/pro-page/card.svg"> ${paymentMethods}
                </p>
              </div>
            </section>
            <section class="profile">
              <!--<img src="${photo}" />-->
              <p style="display: ${ (companyDescription === null) ? 'none' : 'block'}">
                ${companyDescription}
              </p>
            </section>
            <div class="mobile">
              <h2 class="services-title"> Services </h2>
              ${formatServices(services)}
              ${window.innerWidth < 900 ? '<div id="map"></div>' : ''}
            </div>
            <div class="rev">
              <div class="rev-header">
                <h2>
                  Reviews
                </h2>
                <a data-no-instant href="/add-review/#${slug}"> <img src="https://gotidy.gumlet.io/pro-page/chat.svg"> Write Review </a>
              </div>
              <p class="rev-empty" style="display: ${ (reviews.length> 0) ? 'none' : 'block'}">
                No Reviews
              </p>
              <div class="rev-customer-list">
                ${formatReviews(reviews)}
                <div class="rev-customer-pagination" style="display: ${ (reviews.length < 6) ? 'none' : 'block'}">
                  <a href="" class="review-item-button regular-link">
                    View More Reviews
                  </a>
                </div>
              </div>
            </div>
            <div class="certification">
              <h2>
                Certifications
              </h2>
              <p class="certification-empty" style="display: ${certifications.length> 0 ? 'none' : 'block'}">
                No Certifications
              </p>
              <div>
                ${formatCertifications(certifications)}
              </div>
            </div>
        </section>
        <aside class="desktop">
          <h2> Book Now </h2>
          <div>
            <a href="https://app.tidy.com/#/create-account/${encodedUrl}" class="green-button">
              View Availability
            </a>
            <a href="https://app.tidy.com/#/log-in/${encodedUrl}" class="regular-link">
              Log in
            </a>
          </div>
          <div class="desktop">
            <h2 class="services-title"> Services </h2>
            ${formatServices(services)}
            ${window.innerWidth > 900 ? '<div id="map"></div>' : ''}
          </div>
        </aside>
      </section>`;
    return data;
 }

 const formatServices = (services) => {
   const servicesTemplate = services.reduce((acc, item, index) => {
     const template = `
        <div class="certification-list">
          <div class="certification-image">
            <img src="https://gotidy.gumlet.io/pro-page/${item.slug}.svg">
          </div>
          <div class="certification-status">
            <p class="certification-status-category">
              ${item.text}
            </p>
          </div>
        </div>`
      acc.push(template);
      return acc;
   }, []);
   return servicesTemplate.join('');
 };

 const formatCertifications = (certifications, hideText) => {
   const certificationsTemplate = certifications.reduce((acc, item) => {
     const {slug, name, text} = item;
     const template = `
        <div class="certification-list">
          <div class="certification-image">
            <img src="https://gotidy.gumlet.io/pro-page/${slug}.svg">
          </div>
          <div class="certification-status">
            <p class="certification-status-category">
              ${name}
            </p>
            <p class="certification-status-text">${text}</p>
          </div>
        </div>`
      acc.push(template);
      return acc;
   }, []);
   return certificationsTemplate.join('');
 };

 const formatReviews = (reviews) => {
   const reviewsTemplate = reviews.reduce(function(acc, item, index) {
     const paginateItemClass = (index>4)? 'paginate-item' : '';
     const template =`
      <article class="rev-comment ${paginateItemClass}">
        <div class="rev-comment-name">
          <span class="rev-comment-name-circle">
            ${item.client_initials}
          </span>
          <span class="rev-comment-name-complete desktop">
            ${item.client_name}
          </span>
        </div>
        <div class="rev-notes">
          <div class="rev-notes-star">
            <div class="reviews">
              <span class="rev-comment-name-complete mobile">
                ${item.client_name}
              </span>
              <div class="star-group">
                ${formatReviewStars(item.review_stars)}
              </div>
              <div class="rev-days-ago">
               ${daysAgoInWords(item.review_date)}
              </div>
            </div>
          </div>
          <div class="rev-notes-text">
            ${item.review_content}
          </div>
        </div>
      </article>`
      acc.push(template);
      return acc;
   }, []);
   return reviewsTemplate.join('');
 };

 const daysAgoInWords = (date) => {
  let today = new Date();
  let createdOn = new Date(date);
  const msInDay = 24 * 60 * 60 * 1000;
  createdOn.setHours(0,0,0,0);
  today.setHours(0,0,0,0)
  const diff = (+today - +createdOn) / msInDay;
  const plural = ( diff >1 ) ? 's': '';
  return `${Math.floor(diff)} day${plural} ago`;
};


const formatReviewStars = (stars) => {
  const star = '<div class="review-star"><span ></span></div>';
  let data = Array.from(new Array(stars)).map(()=> star);
  data = data.join('');
  return data;
};

const createEmptyStar = () => {
  let star = '<img src="https://gotidy.gumlet.io/pro-page/grey-star.svg">';
  let stars = '';
  for (let index = 0; index < 5; index++) {
    stars += star;
  }
  return stars;
};

const formatStars = (percent) => {
  if (percent ===0){
    return createEmptyStar();
  }
  let decimal = percent.toLocaleString().split(',').pop();
  let startInProgress = (decimal.length === 1) ? `${decimal}0` : '';
  const formatPercent = Math.floor(percent);
  const star = '<div class="review-star"><span ></span></div>';
  let data = Array.from(new Array(formatPercent)).map(()=> star);
  data = data.join('');
  data += `<div class="review-star last-star"><span  style="width: ${startInProgress}%"> </span></div>`;
  return data;
};
