export function encodeUrlParam(url) {
  if (!url){
    return null;
  }

  const encodedUrl = encodeURIComponent(url);
  const fixReserved = replaceReserved(encodedUrl);
  return fixReserved;
};

function replaceReserved(url) {
  const reserved = {
    '\'': '%27'
  };

  let replacedStr = url;
  for (var char in reserved) {
    replacedStr = replacedStr.replace(new RegExp(char, 'g'), reserved[char]);
  }
  return replacedStr;
};
