'use strict';
import IMask from 'imask';

window.addEventListener('load', () => {
  if (window.IMask && document.getElementById('giftAmount') !== null) {
    new IMask(
      document.getElementById('giftAmount'), {
        mask: '$num',
        blocks: {
          num: {
            mask: Number,
            thousandsSeparator: ' '
          }
        }
    });
  }
});
