'use strict';
import { HomekeeperPage } from './custom-pro-website/pro-page';
import { Gift } from './gift';
import { AddReview } from './add-review'
import './gift-success'
import './mask';
import { Unsubscribe } from './unsubscribe';
import { ENV_TIDY } from './env/env';

window.addEventListener('load', () => {
  console.log('load');

  if (document.getElementById('gift')){
    new Gift();
  }

  if (document.querySelector('#hk-page')){
    new HomekeeperPage();
  }

  if (document.querySelector('#add-review')) {
    new AddReview();
  }

  if (document.getElementById('unsubscribe-page')) {
    new Unsubscribe();
  }
});

document.addEventListener('readystatechange', () => {
  const referralCode = window.location.href.split('/r/')[1];
  if (referralCode) {
    window.location.href = `${ENV_TIDY.clientAppUrl}create-account/?referral-code=${referralCode}`;
  }

  const urlArray = window.location.href.split('/');
  const clientIndex = urlArray.indexOf('client');
  if (clientIndex !== -1) {
    window.location.href = `${ENV_TIDY.clientAppUrl}client/${urlArray[clientIndex+1]}`;
  }
});
