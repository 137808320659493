'use strict';
import $ from 'jquery';
import { ENV_TIDY } from './env/env';
export class AddReview {
  constructor(){
    this.disableHover = false;
    this.rating = 0;
    this.isInvalidForm = false;
    this.profile = {};
    this.slug = '';
    this.env = ENV_TIDY;
    this.loading = false;
    this.init();
  }

  init(){
    this.getHkData();
    this.checkHkData();
    this.addEventListeners();
  }

  addEventListeners() {
    $('#hk-page').on('submit','form', this.postReview.bind(this));
    $('.review-star').on('click', this.changeStatus.bind(this));
    $('.review-star').on('mouseenter', this.hoverStatus.bind(this));
    $('.review-star').on('mouseleave',this.removeStatus.bind(this));
    $('.go-back').on('click', () => window.location.href = `/${this.slug}` );
  }

  getHkData(){
    this.profile = JSON.parse(sessionStorage.getItem('hkProfile'));
    $('h1').html(this.profile.name);
  }

  checkHkData(){
    this.slug = window.location.hash.slice(1);
    if (this.slug !== this.profile.slug){
      window.location.href = `/${this.slug}`;
      return;
    }
  }

  removeStatus(){
    if (this.disableHover){
      return;
    }
    $('.review-star').addClass('grey-star');
  }

  changeStatus(e){
    this.setStars(e);
    this.rating =  this.getRating(e);
    this.disableHover = true;
  }

  getRating(e){
    const target = $(e.currentTarget);
    return target.data('val');
  }

  hoverStatus(e){
    if (this.disableHover){
      return;
    }
    this.setStars(e);
  }

  setStars(e){
    const val = this.getRating(e);
    $('.review-star').addClass('grey-star');
    $(`.grey-star:nth-child(-n+${val})`).removeClass('grey-star');
  }

  isValid() {
    this.isInvalidForm = false;
    $('.invalid-feedback').hide();
    if ( $('#first-name').val() === '') {
      $('.invalid-name').show();
      this.isInvalidForm = true;
    }
    if ( $('#last-name').val() === '') {
      $('.invalid-last-name').show();
      this.isInvalidForm = true;
    }
    if ( $('textarea').val() === '') {
      $('.invalid-review').show();
      this.isInvalidForm = true;
    }
    if ( this.rating === 0) {
      $('.invalid-rating').show();
      this.isInvalidForm = true;
    }
  }

  postReview (e) {
    e.preventDefault();
    this.isValid() ;
    if (this.loading) {
      return;
    }
    if (this.isInvalidForm) {
      return;
    }

    const name = $('#name').val().split(' ');
    const firstName = name[0];
    let lastName = name[name.length -1];
    const review = $('#review').val();

    const data = {
      team_id: this.profile.teamId,
      first_name: firstName,
      last_name: lastName,
      stars: this.rating,
      review: review
    };

    this.loading = true;
    $.ajax({
      url: `${this.env.url}team-reviews`,
      method: 'POST',
      contentType: 'application/json',
      data: JSON.stringify(data),
      dataType: 'json'
    }).done((resp)=>{
      if (resp){
        $('#review-form').hide();
        $('#form-review-success').show();
      }
    });
  }
}
