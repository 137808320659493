'use strict';
import $ from 'jquery';

window.addEventListener('load', () => {
  const giftSuccess = document.getElementById('gift-success');
  console.log('tidy');
  console.log('tidy');
  if (giftSuccess){
    let data = {};
    data = JSON.parse(localStorage.getItem('gift-data'));
    if (data) {
      $('#your-data').html(data.yourData);
      $('#recipient-data').html(data.recipientData);
      $('#amount').html(data.mount);
      $('#invoice-send-data').html(data.data);
      $('#gift-date').html(data.data);
    }
  }
});
