'use strict';
import $ from 'jquery';
import { generateHkPage } from './template-pro-page';
import { LoadMap } from './load-map';
import { ENV_TIDY } from '../env/env';
import { encodeUrlParam } from '../helper/encode-url-param';

export class HomekeeperPage {
  constructor(){
    this.jsonFormated = {};
    this.env = ENV_TIDY;
    this.certifications = {
      'background_check': {
        name: 'Background check',
        text: ''
      },
      'certification': {
        name: 'Certification',
        text: ''
      },
      'recently_active': {
        name: 'Recently active',
        text: ''
      },
      'has_equipment': {
        name: 'Cleaning Equipment',
        text: ''
      },
      'english_speaking': {
        name: 'English Speaking',
        text: ''
      },
    };
    this.slug = '';
    this.certificationsFormated = [];
    this.init();
  }

  init(){
    this.loadData();
    this.addEventListeners();
  }

  addEventListeners() {
    $('#hk-banner').on('click', '.review-item-button', this.paginate.bind(this));
  }

  paginate(e){
    e.preventDefault();
    $('.paginate-item').removeClass('paginate-item');
    $('.review-item-button').hide();
  }

  loadData(){
    this.slug = this.getUrl();
    if(!this.slug) {
      return;
    }

    $.ajax({
      url: this.env.url + `teams/${this.slug}`,
      method: 'get',
      dataType: 'json'
    })
    .fail(()=> this.show404())
    .done((data) => this.successLoad(data));
  }

  successLoad (data) {
    this.setPageTitle(data);

    try {
      this.jsonFormated = this.formtHomekeeperTeam(data, this.slug);
      this.pageTemplate = generateHkPage(this.jsonFormated);
      if (data.background_url) {
        $('#hk-banner').css('background-image', `url(${data.team.background_photo_url})`);
      }
      $('#hk-banner').html(this.pageTemplate);
      LoadMap(data.locations_served);
      const profileData = JSON.stringify({name: data.team.name, hkId: data.team_owner.homekeeper_id, slug: this.slug, teamId: data?.team?.id});
      sessionStorage.setItem('hkProfile', profileData);
    } catch (e) {
      console.log(e);
      this.show404();
    }
  }

  setPageTitle(data) {
    document.title = `${data.team_owner.name} | TIDY`;
  }

  show404(){
    $('#hk-banner').hide();
    $('#hk-banner').css('visibility', 'hidden');
    $('.mobile-bar.mobile').hide();
    $('#custom-404').show();
  }

  formtHomekeeperTeam(data, slug){
    return {
      averageReviews: data.statistics.average_reviews,
      businessName: data.team.name,
      certifications: this.formatCertifications(data.certifications),
      companyDescription: data.team.description,
      encodedHkName: encodeUrlParam(data.team_owner.name),
      encodedTeamName: encodeUrlParam(data.team.name),
      hkId: data.team_owner.homekeeper_id,
      locations: data.locations_served,
      numberJobs: data.statistics.number_of_jobs_in_last_30_days,
      paymentMethods: this.formatPaymentMethods(data.payment_methods),
      phoneNumber: data.team_owner.phone_number,
      photo: data.team.backgroud_photo_url,
      reviews:  data.reviews,
      services: data.services_provided,
      slug: slug
    }
  }

  formatPaymentMethods(paymentOptions) {
    const paymentTypes = {
      1: "Cash",
      2: "Check",
      3: "Card",
      4: "Venmo",
      5: "PayPal",
      6: "Cash App",
      7: "Zelle",
      8: "Other"
    };
    const formatedOptions = paymentOptions.map( paymentOption => {
      return paymentTypes[paymentOption.team_payment_option_type_id];
    });
    return `${formatedOptions.join(', ')}.`;
  }

  formatCertifications(data){
    const certificationData = [];
    for (const key in data) {
      if (data && data.hasOwnProperty(key) && this.certifications.hasOwnProperty(key) && data[key] !== '') {
        const element = {
          text: this.certifications[key].text,
          name: this.certifications[key].name,
          slug: key
        };
        certificationData.push(element);
      }
    }
    return certificationData;
  }

  getUrl(){
    // on development link http://localhost:1313/custom_404/garrettgooden
    // on production link http://tidy.com/garrettgooden

    const data = window.location.href.split('/');

    this.checkClientRedirect(data);
    let param = data[data.length -1];
    if (!param){
      param = data[data.length -2];
    }
    return param;
  }

  checkClientRedirect(urlArray) {
    const clientIndex = urlArray.indexOf('client');

    if (clientIndex !== -1) {
      window.location.href = `${ENV_TIDY.clientAppUrl}client/${urlArray[clientIndex+1]}`;
    }
  }
}
